<template>
  <auth>
    <div class="right-content">
      <div class="login-header" id="main-content">
        <div class="brand">
          <h4>Validate Account</h4>
        </div>
      </div>
      <hr />
      <div v-html="$error.handle(error)" />
      <form @submit.prevent="submit">
        <div class="form-group">
          <label>Enter the OTP sent to your number</label>
          <input
            name="code"
            v-model="code"
            type="text"
            class="form-control"
            required
          />
        </div>
        <div class="form-group">
          <button class="btn btn-warning" v-if="loading">
            Sending <i class="fa fa-spinner spin"></i>
          </button>
          <button class="btn btn-success" v-else type="submit">
            Confirm <i class="fa fa-check-circle"></i>
          </button>
          <button class="btn btn-warning ml-3" v-if="timerEnabled">
            Retry after {{ timerCount }} seconds
            <i class="fa fa-spinner spin"></i>
          </button>
          <button
            class="btn btn-primary ml-3"
            v-else
            type="button"
            @click.prevent="resendOtp"
          >
            Resend Otp <i class="fa fa-sms"></i>
          </button>
        </div>
      </form>
    </div>
  </auth>
</template>

<script>
import Auth from "@/components/Auth.vue";

export default {
  components: {
    Auth,
  },
  data() {
    return {
      email: this.$route.params.email,
      code: "",
      loading: false,
      error: "",
      timerEnabled: false,
      timerCount: 30,
      otploading: false,
    };
  },
  computed: {
    user() {
      return this.$store.getters.getAuthUser;
    },
  },
  watch: {
    timerEnabled(value) {
      if (value) {
        setTimeout(() => {
          this.timerCount--;
        }, 1000);
      }
    },

    timerCount: {
      handler(value) {
        if (value > 0 && this.timerEnabled) {
          setTimeout(() => {
            this.timerCount--;
          }, 1000);
        }
      },
      immediate: true, // This ensures the watcher is triggered upon creation
    },
  },
  methods: {
    submit() {
      this.loading = true;
      this.$axios
        .post("/api/v1/otp/auth", { email: this.user.email, code: this.code })
        .then((res) => {
          this.$toastr.s("Account verified!");
          const data = res.data;
          this.$auth.setToken(data.access_token);
          this.$axios.defaults.headers.Authorization =
            "Bearer " + this.$auth.getToken();
          this.$store.dispatch("setAuthUser", data).then(() => {
            window.location.href = this.$router.resolve({
              name: "dashboard",
            }).href;
          });
        })
        .catch((error) => {
          if (error.response) {
            this.error = error.response;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    resendOtp() {
      this.timerEnabled = true;
      this.$axios
        .post("/api/v1/otp/resend", { email: this.user.email })
        .then((res) => {
          this.$toastr.s(res.data.message);
        })
        .catch((error) => {
          alert("caught error");
          this.timerEnabled = false;
          if (error.response) {
            this.error = error.response;
          }
        });
    },
  },
};
</script>

<style type="text/css">
.alert-warning p {
  color: #fd0d39;
}
</style>
